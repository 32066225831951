import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NumbersEnergie from "../components/numbers-energie"
import Piloni from "../components/piloni"

import HeroEnergie from "../images/hero-energie-si-clima.jpg"
import HeroEnergieM from "../images/hero-energie-si-clima-m.jpg"

import ProtectiaClimei from "../images/protectia-climei.jpg"
import ConservareaResurselor from "../images/conservarea-resurselor.jpg"
import LogisticaSiMobiliatea from "../images/logistica-si-mobiliatate.jpg"
import CladiriVerzi from "../images/cladiri-verzi.jpg"

import DownloadIcon from "../images/download.svg"

import Submenu from "../components/submenu"

const Energie = () => (
  <Layout pageInfo={{ pageName: "angajati" }}>
    <SEO title="Energie, Climă și resurse" />
    <section className="bg-light">
      <div className="hero text-center mx-auto">
        <img className="d-none d-lg-block w-100" src={HeroEnergie} alt="" />
        <img className="d-lg-none w-100" src={HeroEnergieM} alt="" />
      </div>
    </section>
    <div className="container">
      <div className="row mt-5">
        <div className="col-10 my-5 mx-auto">
          <h1 className="text-success text-center akko-bold py-3">Depunem eforturi pentru a atinge neutralitate climatică!</h1>
          <p className="akko-regular text-center">
            În efortul nostru de a ne angaja față de mediul înconjurător și de a aduce plusvaloare  prin activitățile noastre, am implementat o serie de măsuri dezvoltate în urma  strategiei noastre de sustenabilitate. Unul dintre obiectivele noastre principale  rămâne cel de a atinge neutralitatea climatică până în 2040. Pentru a realiza acest  lucru, ne propunem să reducem emisiile totale de gaze cu efect de seră cu 30% până  în 2030, comparativ cu nivelul din 2019.
            Păstrăm și dezvoltăm în continuare procese operaționale ce contribuie semnificativ  la reducerea impactului negativ asupra mediului, aspectelor sociale și economice. În  cadrul strategiei noastre, în pilonul „Energie, Climă și Resurse“, am definit patru domenii principale de acțiune:
            „Protecția climei“, „Conservarea resurselor“, „Logistică și mobilitate“ și „Clădiri verzi“.  
          </p>
        </div>
        <div className="col-12 pb-5 text-center">
          <a
            href="/raport-de-sustenabilitate-2023"
            className="btn btn-primary akko-bold mx-auto"
          >
            Descarca raport
            <img className="icon-badge-white ml-2" src={DownloadIcon} alt="" />
          </a>
        </div>
      </div>
    </div>

    <NumbersEnergie />

    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${ProtectiaClimei})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      </div>
      <div className="col-12 col-lg-6">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3">PROTECȚIA CLIMEI</h1>
          <p className="mb-0">
            Prin specificul activității noastre, consumul de energie  reprezintă principalul aspect ce influențează emisiile noastre directe în atmosferă. Consumul de energie este un aspect de bază în industria în care activăm, având un impact direct asupra proceselor operaționale, de la  refrigerare și iluminat până la funcționarea sistemelor informatice ce asigură integritatea activității de  vânzare cu amănuntul. Astfel, gestionarea eficientă  a consumului de energie aduce un impact pozitiv asupra companiei noastre prin scăderea costurilor operaționale, contribuind în același timp la protecția climei și reducerea impactului negativ asupra mediului înconjurător.
            <br/><br/>
            Monitorizăm în continuare consumul energetic și emisiile rezultate din activitatea noastră, utilizând  diferite instrumente și metodologii de specialitate, cum ar fi Modelul global de emisii pentru sisteme integrate  (GEMIS), instrument ce calculează emisii GES, grad de  utilizare al resurselor și costuri aferente ciclului de viață  complet pentru o varietate de lanțuri de combustibil. <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/137/">Află mai multe</a>.
            <br/><br/>
            Pentru a putea dezvolta măsuri specifice de protecție a climei și pentru a putea urmări  performanțele în acest domeniu, în fiecare an calculăm amprenta noastră de carbon. <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/169/">Află mai multe</a>. 

          </p>
        </div>
      </div>
    </div>
    <div className="section-info section-left row no-gutters">
      <div className="col-12 col-lg-6 order-2 order-lg-1">
        <div className="section-text py-6 py-lg-8 pl-20 pl-lg-6 pl-xl-8 pl-xxl-12 pr-20 pr-xl-8">
          <h1 className="text-success akko-bold mb-3">CONSERVAREA RESURSELOR</h1>
          <p className="m-0">
            Indiferent de domeniul de activitate, toate afacerile se  bazează pe resursele naturale. În cazul comerțului cu  amănuntul, disponibilitatea materiilor prime este un  factor cheie care influențează semnificativ activitatea,  nu doar prin implicații financiare directe asupra lanțului  de aprovizionare, dar și prin apariția unei serii de  obligații legale ce reglementează responsabilitatea producătorilor din punct de vedere al răspunderii extinse și a economiei circulare. Astfel, conservarea resurselor înseamnă pentru noi o  oportunitate de optimizare a proceselor operaționale  și necesitatea de a îndeplini anumite obligații legale. În  fiecare an, monitorizăm consumul de apă, materiale  consumabile, ambalaje și deșeuri generate. <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/153/">Află mai multe</a>.
            <br/><br/>
            În cadrul activităților noastre de marketing și  promovare, ne concentrăm pe utilizarea hârtiei reciclate  și a pliantelor ecologice, precum și pe alte suporturi  imprimate prietenoase cu mediul înconjurător. <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/154/">Află mai multe</a>.
            <br/><br/>
            Susținerea biodiversității reprezintă un subiect important  în contextul sustenabilității, iar în cadrul PENNY depunem  în mod constant eforturi pentru a contribui la protejarea  speciilor și habitatelor din zonele unde ne desfășurăm  activitatea. Astfel, prin plantările noastre peisagistice, vizăm o creștere a faunei sălbatice. <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/154/">Află mai multe</a>.
            <br/><br/>
            În cadrul proceselor noastre logistice, continuăm  implementare soluțiilor sustenabile pentru a reduce  generarea deșeurilor. Astfel, în anul 2022 am continuat  colaborarea cu CHEP* pentru reutilizarea paleților de lemn și cu IFCO** pentru implementarea programului de  reutilizare a containerelor de plastic (CPR). <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/158/">Află mai multe</a>.
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-2">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${ConservareaResurselor})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      </div>
    </div>
    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${LogisticaSiMobiliatea})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      </div>
      <div className="col-12 col-lg-6">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3 text--uppercase">Logistică și mobilitate</h1>
          <p className="mb-0">
            Logistica este o zonă operațională strategică în cadrul  oricărei activități de retail. Așadar, impactul asupra  companiei noastre este semnificativ, nu doar din punct  de vedere al implicațiilor operaționale ci și din punct de  vedere financiar. Deoarece acest segment de activitate stă la baza bunei funcționări a activității noastre,  perturbarea proceselor logistice poate avea un efect în  cascadă cu consecințe asupra întregii activități.
            <br/><br/>
            În același timp, transportul rutier poate avea un impact negativ semnificativ pentru mediul înconjurător și  societate prin poluarea aerului cu gaze de ardere și gaze de eșapament. <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/165/">Află mai multe</a>.
            <br/><br/>
            În vederea reducerii impacturilor negative cauzate de transportul folosind vehicule cu  combustie internă, am dezvoltat o serie de facilități care permit colegilor și clienților noștri să utilizeze metode alternative de transport. Astfel, punem la dispoziția persoanelor care ne trec pragul locuri de parcare  pentru biciclete. Totodată, pentru a contribui la potențiala reducere a emisiilor de gaze cu efect de seră  în lanțul nostru valoric, oferim clienților noștri posibilitatea de a opta pentru livrare  la domiciliu. Deoarece dorim să contribuim la dezvoltarea tehnologiilor ce susțin protecția climei,  ne-am extins rețeaua de stații de încărcare a autovehiculelor electrice. <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/167/">Află mai multe</a>.
          </p>
        </div>
      </div>
    </div>
    <div className="section-info section-left row no-gutters">
      <div className="col-12 col-lg-6 order-2 order-lg-1">
        <div className="section-text py-6 py-lg-8 pl-20 pl-lg-6 pl-xl-8 pl-xxl-12 pr-20 pr-xl-8">
          <h1 className="text-success akko-bold mb-3 text--uppercase">
            Clădiri Verzi
          </h1>
          <p className="m-0">
            Dezvoltarea de clădiri verzi în cadrul rețelei noastre  de amplasamente reprezintă o direcție strategică de  importanță deosebită pentru compania noastră, ce ne ajută să ne îndeplinim angajamentele de sustenabilitate asumate. Prin includerea cât mai multor clădiri verzi în  rețeaua noastră, facilităm extinderea afacerii noastre  păstrând un impact redus asupra mediului înconjurător.
            <br/><br/>
            Continuăm aplicare conceptului de clădire sustenabilă  certificată BREEAM in Usetuturor proiectelor de construcții  noi. Prin extinderea rețelei de clădiri verzi urmărim  îndeplinirea unor condiții de îmbunătățire a condițiilor  operaționale, cum ar fi conservarea resurselor, reducerea  consumurilor, gestionarea corectă a deșeurilor, ce duc  la reducerea impacturilor negative asupra mediului și  îmbunătățirea condițiilor pentru ocupanți.
            Pe baza acestor criterii dezvoltăm și implementăm în  mod constant acțiuni pentru extinderea rețelei noastre  de clădiri  verzi. <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2023/176/">Află mai multe</a>.
            <br></br>
            <br></br>
            Urmărim îndeplinirea îmbunătățirii condițiilor operaționale și a aspectelor precum reducerea consumurilor de energie și apă, conservarea resurselor, gestionarea corectă a deșeurilor. Continuăm să extindem rețeaua noastră de clădiri verzi, prin certificarea BREEAM in use Part 2 a cladirilor noastre nou construite. <a href="https://batchgeo.com/map/370ee79e323eb3193bba1b7f6c5869b7"> Afla mai multe</a>.
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-2">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${CladiriVerzi})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      </div>
    </div>{" "}
    <div className="bg-light pt-5 mb-5">
      <div className="container">
        <div className="row pt-4">
          <div className="col-12">
            <div className="d-block text-center mb-3">
              <a
                href="/documente/politica-energetica.pdf"
                className="btn btn-primary akko-bold mx-auto"
              >
                Politică energetică
                <img className="icon-badge-white ml-2" src={DownloadIcon} alt="" />
              </a>
            </div>
            <div className="d-block text-center">
              <a
                href="/documente/program-de-prevenire-si-reducere-deseuri/"
                className="btn btn-primary akko-bold mx-auto"
              >
                Program de prevenire și reducere a deșeurilor 
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 my-4">
          <div className="video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Rv6K-fs7284"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
        <div className="row pb-5">
          <a
            href="/raport-de-sustenabilitate-2023"
            className="btn btn-primary akko-bold mx-auto"
          >
            Descarca raport
            <img className="icon-badge-white ml-2" src={DownloadIcon} alt="" />
          </a>
        </div>
      </div>
    </div>
    <div className="container">
      <Piloni hide="2" />
    </div>
  </Layout>
)

export default Energie
